import { styled } from "@mui/material";

import React from "react";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import { TrackedMotionButton } from "../../StoreComponents/StoreButton";

const QuantityControlContainer = styled("div")(({ theme }) => ({
  display: "flex",
  backgroundColor: "#f6f8fa",
  color: theme.palette.primary.main,
  width: 80,
  height: 23,
  alignItems: "center",
  borderRadius: 999,
  fontSize: 14,
  button: {
    padding: 0,
  },
  ".control": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "inherit",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    fontWeight: 600,
    color: theme.palette.primary.main,
    transitionProperty: "transform",
    transitionDuration: ".6s",
    cursor: "pointer",
    "&:active": {
      transform: "scale(.9)",
    },
    "&.minus": {
      paddingLeft: 8,
    },
    "&.add": {
      paddingRight: 8,
    },
  },
  ".count": {
    cursor: "default",
    fontSize: "inherit",
    fontWeight: 600,
    flex: 1,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  "&.large": {
    width: 96,
    height: 30,
    fontSize: 14,
    ".control": {
      "&.minus": {
        paddingLeft: "10px",
      },
      "&.add": {
        paddingRight: "10px",
      },
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 68,
      height: 27,
      ".control": {
        "&.minus": {
          paddingLeft: "6px",
        },
        "&.add": {
          paddingRight: "6px",
        },
      },
    },
  },
}));
const QuantityControl = ({
  large = false,
  count,
  onAddClick,
  onMinusClick,
  trackedParams,
  disabled = false,
}: {
  large?: boolean;
  count: number;
  onAddClick: React.MouseEventHandler<HTMLButtonElement>;
  onMinusClick: React.MouseEventHandler<HTMLButtonElement>;
  trackedParams?: Record<string, any>;
  disabled?: boolean;
}) => {
  return (
    <QuantityControlContainer
      className={large ? "large" : ""}
      role="button"
      style={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      onClick={(e) => {
        if (disabled) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <TrackedMotionButton
        onClick={(e) => onMinusClick(e)}
        className="minus control"
      >
        -
      </TrackedMotionButton>
      <div className="count">{count}</div>
      <TrackedMotionButton
        onClick={(e) => onAddClick(e)}
        className="add control"
      >
        +
      </TrackedMotionButton>
    </QuantityControlContainer>
  );
};

export default QuantityControl;
