import StoreAddressAutocompleteInput, {
  autocompleteAddressType,
} from "@/components/StoreComponents/StoreAddressAutocompleteInput";
import { SolidButton } from "@/components/StoreComponents/StoreButton";
import StoreInput from "@/components/StoreComponents/StoreInput";
import { useAppSelector } from "@/redux/hooks";
import { useEffect } from "react";
import { useCheckoutUI } from "../../CheckoutUIProvider";
import { useCheckout } from "../../FastCheckoutProvider";

export const ShippingMethodForm = () => {
  const [machineState, send] = useCheckoutUI();
  const { amountToCharge } = useCheckout();
  const userProfile = useAppSelector((state) => state.userProfile);
  const addresses = userProfile?.addresses;

  const defaultShippingAddress = addresses?.find(
    (address) => address.id === userProfile?.defaultShippingAddressId
  );

  const { shipping } = machineState.context;

  const { firstName, lastName, address1, address2, city, state, zipCode } =
    shipping || {};

  useEffect(() => {
    if (defaultShippingAddress) {
      const { firstName, lastName, address1, address2, city, state, zipCode } =
        defaultShippingAddress;

      send({
        type: "SET_SHIPPING",
        data: { firstName, lastName, address1, address2, city, state, zipCode },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultShippingAddress, send]);

  const handleContinue = () => {
    // TODO: validate here

    if (machineState.value !== "shipping") {
      send({ type: "EDIT", section: "shipping" });
      return;
    }

    if (amountToCharge) {
      console.log("amountToCharge ====>", amountToCharge);
      send({ type: "SET_AMOUNT", data: amountToCharge });
    }

    send({ type: "NEXT" });
  };

  const updateShipping = (data: any) => {
    send({ type: "SET_SHIPPING", data });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <h3>Shipping Address</h3>
      <StoreInput
        label="First name"
        value={firstName}
        onChange={(e) =>
          updateShipping({ ...shipping, firstName: e.target.value })
        }
        sx={{ maxWidth: "100%" }}
      />
      <StoreInput
        label="Last name"
        value={lastName}
        onChange={(e) =>
          updateShipping({ ...shipping, lastName: e.target.value })
        }
        sx={{ maxWidth: "100%" }}
      />
      <StoreAddressAutocompleteInput
        searchTerm={address1}
        selectAddressCallback={(address: autocompleteAddressType) => {
          updateShipping({
            ...shipping,
            address1: address?.line1 || "",
            address2: address?.line2 || "",
            city: address?.city || "",
            state: address?.state || "",
            zipCode: address?.zipCode || "",
          });
        }}
        renderInput={(param: any) => (
          <StoreInput
            {...param}
            placeholder="Begin typing to search"
            label={"Address"}
            onChange={(e) =>
              updateShipping({ ...shipping, address1: e.target.value })
            }
            value={address1}
            InputProps={{
              ...param.InputProps,
            }}
            inputProps={{
              ...param.inputProps,
              value: address1,
            }}
            sx={{ maxWidth: "100%" }}
          />
        )}
      />

      <StoreInput
        label="Address 2"
        value={address2}
        onChange={(e) =>
          updateShipping({ ...shipping, address2: e.target.value })
        }
        sx={{ maxWidth: "100%" }}
      />

      <StoreInput
        label="City"
        value={city}
        onChange={(e) => updateShipping({ ...shipping, city: e.target.value })}
        sx={{ maxWidth: "100%" }}
      />
      <StoreInput
        label="State"
        value={state}
        onChange={(e) => updateShipping({ ...shipping, state: e.target.value })}
        sx={{ maxWidth: "100%" }}
      />
      <StoreInput
        label="Zip code"
        value={zipCode}
        onChange={(e) =>
          updateShipping({ ...shipping, zipCode: e.target.value })
        }
        sx={{ maxWidth: "100%" }}
      />
      <SolidButton
        onClick={handleContinue}
        style={{ marginTop: "16px", width: "100%" }}
      >
        Continue to Payment
      </SolidButton>
    </div>
  );
};
