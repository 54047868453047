import { CloseButton } from "@brandclub/common-ui";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MOBILE_SCREEN_SIZE } from "@/components/AppNavigation/constants";
import EnterMFACode from "@/components/pages/AppPages/Sync/MFA/EnterMFACode";
import { MFADialogProps } from "../types";

const StyledEnterMFACode = styled(EnterMFACode)(() => ({
  flex: 1,
  minHeight: "unset",
  "& .auth_container": {
    justifyContent: "center",
  },
}));

export const EnterMFACodeDialog = (props: MFADialogProps) => {
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  return (
    <Dialog {...props.dialogProps} fullScreen={isMobileView}>
      <Box
        sx={{
          marginBottom: "20px",
          width: "600px",
          maxWidth: "100%",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            marginBottom: 0,
          },
        }}
      >
        <Box
          component="button"
          sx={{
            background: "none",
            border: "none",
            outline: "none",
            alignSelf: "flex-end",
            padding: 0,
            marginTop: "18px",
            marginRight: "18px",
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              marginTop: "38px",
              marginRight: "20px",
            },
          }}
        >
          <CloseButton iconSize={12.5} onClick={props.handleDialogClose} />
        </Box>
        <StyledEnterMFACode
          retailerId={props.retailerId}
          retailerSyncSession={props.retailerSyncSession}
          onCompleteCallback={props.onCompleteCallback}
        />
      </Box>
    </Dialog>
  );
};
