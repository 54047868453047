import { SuccessCheckCircleIcon } from "@brandclub/common-ui";
import { styled } from "@mui/material";
import ConnectRetailer from "../../../../../pages/AppPages/Sync/Connect";
import { useCheckoutRetailer } from "../../hooks/useCheckoutRetailer";
import { useCheckoutUI } from "../../CheckoutUIProvider";

const RetailerStatusContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "12px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  border: "1px solid #dedede",
});

const RetailerName = styled("span")({
  fontSize: "16px",
  fontWeight: 500,
  color: "#000",
});

const ConnectedStatus = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

export const RetailerLoginForm = () => {
  const { currentRetailer, isConnected, retailerSyncStatus } =
    useCheckoutRetailer();

  const [state, send] = useCheckoutUI();

  const { savingRetailerLogin } = state.context;

  if (!currentRetailer) {
    return null;
  }

  if (savingRetailerLogin) {
    return <div>Saving retailer login...</div>;
  }

  return (
    <RetailerStatusContainer>
      {isConnected ? (
        <ConnectedStatus>
          <SuccessCheckCircleIcon />
          <RetailerName>
            Connected to {currentRetailer.displayName}
          </RetailerName>
        </ConnectedStatus>
      ) : (
        <ConnectRetailer
          retailerId={currentRetailer.retailerId}
          errorMsg={retailerSyncStatus?.errorMessage}
          redirectToSync={false}
          connectOverwrite={(userName, password) => {
            send({
              type: "SET_RETAILER_CONNECTION",
              data: {
                userName,
                password,
                retailerId: currentRetailer.retailerId,
              },
            });
          }}
          buttonText={`Save`}
        />
      )}
    </RetailerStatusContainer>
  );
};
