import { CheckoutProvider } from "@/components/Checkout/CheckoutMachine";
import LandingPageWrapper from "@/components/pages/AppPages/Pages/components/LandingPageWrapper";
import { useAppSelector } from "@/redux/hooks";
import { styled } from "@mui/material";
import { useEffect } from "react";
import { CheckoutUIProvider, useCheckoutUI } from "../CheckoutUIProvider";
import { FastCheckoutProvider } from "../FastCheckoutProvider";
import { useCheckoutRetailer } from "../hooks/useCheckoutRetailer";
import { CheckoutEditPaymentForm } from "./components/CheckoutEditPaymentForm";
import CollapsibleSection from "./components/CollapsibleSection";
import { CurrentCart } from "./components/CurrentCart";
import DebugPanel from "./components/DebugPanel";
import { RetailerLoginForm } from "./components/RetailerLoginForm";
import { ShippingMethodForm } from "./components/ShippingMethodForm";
import SignIn from "./SignIn";

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  marginTop: "60px",
  gap: "60px",
  ".content": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  ".sidebar": {
    width: "430px",
  },
});

const CheckoutSummaryInner = () => {
  const { currentRetailer, isConnected, retailerSyncStatus } =
    useCheckoutRetailer();
  const userProfile = useAppSelector((state) => state.userProfile);
  const [state, send] = useCheckoutUI();
  const { openSections, isRetailerConnected } = state.context;

  useEffect(() => {
    if (currentRetailer) {
      send({ type: "SET_CURRENT_RETAILER", data: currentRetailer });
    }
  }, [currentRetailer, send]);

  useEffect(() => {
    if (userProfile?.userId && state.value === "initial") {
      send({ type: "SET_USER_ID", data: userProfile?.userId });
    }

    if (isConnected && !state.context.isRetailerConnected) {
      send({ type: "SET_RETAILER_CONNECTION", data: isConnected });
    }
  }, [
    isConnected,
    send,
    userProfile?.userId,
    state.context.userId,
    state.context.isRetailerConnected,
    state.value,
  ]);

  const toggleSection = (step: keyof typeof openSections) => {
    send({ type: "EDIT", section: step });
  };

  const completedPayment = (value: any) => {
    return ["payment_success", "success"].includes(value);
  };

  const completedShipping = (value: any) => {
    return ["payment", "processing", "payment_success", "success"].includes(
      value
    );
  };

  return (
    <LandingPageWrapper includeFooter={false}>
      <Container>
        <div className="content">
          <CollapsibleSection
            isOpen={openSections.signIn}
            onToggle={() => toggleSection("signIn")}
            title={userProfile?.userId ? "Signed in ✅" : "Sign In"}
            disabled={!!userProfile?.userId}
            hideControl={!!userProfile?.userId}
          >
            {userProfile?.userId ? <div>Signed in</div> : <SignIn />}
          </CollapsibleSection>

          <CollapsibleSection
            isOpen={openSections.retailerLogin}
            onToggle={() => toggleSection("retailerLogin")}
            title={
              isRetailerConnected
                ? `${currentRetailer?.displayName || "Retailer"} Account ✅`
                : `${currentRetailer?.displayName || "Retailer"} Account`
            }
            error={!!retailerSyncStatus?.errorMessage}
          >
            <RetailerLoginForm />
          </CollapsibleSection>

          <CollapsibleSection
            isOpen={openSections.shipping}
            onToggle={() => toggleSection("shipping")}
            title={completedShipping(state.value) ? "Shipping ✅" : "Shipping"}
          >
            <ShippingMethodForm />
          </CollapsibleSection>

          <CollapsibleSection
            isOpen={openSections.payment}
            onToggle={() => toggleSection("payment")}
            title={completedPayment(state.value) ? "Payment ✅" : "Payment"}
          >
            <CheckoutEditPaymentForm />
          </CollapsibleSection>
        </div>
        <div className="sidebar">
          <CurrentCart />
          <DebugPanel />
        </div>
      </Container>
    </LandingPageWrapper>
  );
};

const CheckoutSummary = () => {
  return (
    <CheckoutProvider>
      <FastCheckoutProvider>
        <CheckoutUIProvider>
          <CheckoutSummaryInner />
        </CheckoutUIProvider>
      </FastCheckoutProvider>
    </CheckoutProvider>
  );
};

export default CheckoutSummary;
