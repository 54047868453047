import { useMachine } from "@xstate/react";
import { checkoutUIMachine } from "./checkoutUIMachine";
import { ReactNode, createContext, useContext } from "react";

type CheckoutUIContextType = ReturnType<typeof useMachine<typeof checkoutUIMachine>>;

const CheckoutUIContext = createContext<CheckoutUIContextType | null>(null);

interface CheckoutUIProviderProps {
  children: ReactNode;
}

export const CheckoutUIProvider = ({ children }: CheckoutUIProviderProps) => {
  const machine = useMachine(checkoutUIMachine);

  return (
    <CheckoutUIContext.Provider value={machine}>
      {children}
    </CheckoutUIContext.Provider>
  );
};

export const useCheckoutUI = () => {
  const context = useContext(CheckoutUIContext);
  if (!context) {
    throw new Error("useCheckoutUI must be used within a CheckoutUIProvider");
  }
  return context;
};
