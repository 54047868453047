import React, { useState, useContext } from "react";
import StoreInput from "../../StoreComponents/StoreInput";
import AuthSolidButton from "./components/AuthSolidButton";
import { UserLoginContext } from "./UserLoginProvider";
import { ReactComponent as LockedAccount } from "../../../assets/locked-account.svg";
import { useTheme } from "@mui/material";
import { ButtonLoadingEllipsis } from "../../StoreComponents/StoreButton";

import OTPWrapper from "./OTPWrapper";

const ROW_SPACING = 20;

const SignInVerify = () => {
  const [code, setCode] = useState("");

  const {
    send,
    snapshot,
    resendCooldown,
    submittingOtp,
    otpError,
    setOtpError,
    verifyOtp,
    resendOtp,
  } = useContext(UserLoginContext);
  const theme = useTheme();

  const submit = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    verifyOtp(code, snapshot.context.cognitoSession, () => {
      setCode("");
      send({ type: "SUBMIT" });
    });
  };

  const resend = async () => {
    resendOtp(
      snapshot.context.otpPhoneNumber,
      snapshot.context.isNewUser,
      (cognitoSession) => {
        send({
          type: "RESEND_VERIFY_OTP",
          cognitoSession,
        });
      }
    );
  };

  const clearErrors = () => {
    setOtpError("");
  };

  return (
    <OTPWrapper>
      <div className="auth_container">
        <LockedAccount
          style={{
            width: 75,
            height: 75,
            fill: theme.palette.primary.main,
          }}
        />
        <div className="page_title">Verify your phone number</div>
        <div className="page_subtitle">
          Please enter your verification code here.
        </div>
        <StoreInput
          errorMsg={otpError || ""}
          value={code}
          autoFocus
          onChange={(e) => {
            clearErrors();
            setCode(e.target.value);
          }}
          placeholder="Verification code"
          inputStyle={{ width: "100%" }}
          style={{ marginTop: ROW_SPACING }}
        ></StoreInput>
        <AuthSolidButton
          disabled={submittingOtp || code.length === 0}
          style={{ marginTop: ROW_SPACING }}
          onClick={submit}
        >
          {submittingOtp ? (
            <>
              Submitting
              <ButtonLoadingEllipsis baseFontSize={16} customColor="#dcdddc" />
            </>
          ) : (
            "Continue"
          )}
        </AuthSolidButton>
        {resendCooldown <= 0 ? (
          <div className="resend">
            Please click{" "}
            <span
              className="click"
              onClick={() => {
                resend();
              }}
            >
              here
            </span>{" "}
            to resend the code.
          </div>
        ) : (
          <div className="resend">
            We sent the new code.
            <br />
            You can resend in {resendCooldown}s.
          </div>
        )}
      </div>
    </OTPWrapper>
  );
};

export default SignInVerify;
