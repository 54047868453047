import { globalCheckoutMachine } from "@/components/Checkout/CheckoutMachine";
import { ActorLogicFrom, ActorRefFrom } from "xstate";
import {
  RetailerCheckoutOrderStatus,
  deleteOutOfStockItemsFromCheckoutOrder,
  updateUserConfirmationToCheckoutItems,
} from "../../api/rest/checkoutApi";
import { UserPurchaseHistory } from "../../types/misc";
import { formatMoney, getEntityImage } from "../../utils/StringUtils";
import {
  ShippingAddress,
  ShoppingCartItem,
  UserRetailerCheckoutOrder,
  UserRetailerShoppingCart,
} from "./types";

export enum retailerLimits {
  "maximumQuantityPerProduct" = 10,
  "maxProductCount" = 20,
}

const getTotal = (products: ShoppingCartItem[]) => {
  return products.reduce((acc, item) => {
    return acc + item.retailPrice * item.quantity;
  }, 0);
};

export const calculateTotalOfAllCarts = (
  carts: Partial<UserRetailerShoppingCart>[]
) => {
  const total = carts.reduce((acc, cart) => {
    return acc + getTotal(cart.items ?? []);
  }, 0);
  return formatMoney(total);
};

export const calculateTotal = (products: ShoppingCartItem[]) => {
  const total = getTotal(products);

  return formatMoney(total);
};

export const isItemCheckoutSuccess = (
  item: ShoppingCartItem | UserPurchaseHistory
) => {
  return (
    !item.status ||
    item.status === "success" ||
    item.status === "partial_success"
  );
};

export const isPurchaseCheckoutSuccess = (
  p: UserRetailerCheckoutOrder | UserPurchaseHistory | undefined
) => {
  return p?.status === "checkout_success";
};

type ItemWithQuantity = {
  quantity?: number;
};
type ItemWithItems = {
  items?: ItemWithQuantity[];
};
export const sumItemQuantities = (
  items: (ItemWithQuantity | undefined)[] | undefined
) => {
  if (!items) {
    return 0;
  }
  return items.reduce((total, item) => total + (item?.quantity ?? 0), 0);
};

export const sumAllItemQuantities = (item: (ItemWithItems | undefined)[]) => {
  return item.reduce((total, i) => total + sumItemQuantities(i?.items), 0);
};

export const flattenCartItems = (carts: Partial<UserRetailerShoppingCart>[]) =>
  carts
    .flatMap((c) => c.items)
    .filter((i): i is ShoppingCartItem => Boolean(i));

export const createRetailerSkuMap = (
  carts: Partial<UserRetailerShoppingCart>[]
) => new Map(flattenCartItems(carts).map((item) => [item.retailerSku, item]));

export const buildAddressString = (
  shippingAddress?: Partial<ShippingAddress>
): string => {
  const address = [
    shippingAddress?.address1,
    shippingAddress?.address2,
    shippingAddress?.city,
    shippingAddress?.state,
  ]
    .map((str) => str?.trim())
    .filter((str) => !!str)
    .join(", ");
  const zipCode = shippingAddress?.zipCode?.trim();
  if (zipCode) {
    return address + " " + zipCode;
  }
  return address;
};

export const getStepFromCartProducts = (
  cartProducts: ShoppingCartItem[] | undefined
) => {
  if (!cartProducts) {
    return [];
  }

  return cartProducts.map((product, index) => {
    return {
      title:
        cartProducts.length > 1
          ? `Checking item ${index + 1} of ${cartProducts.length}`
          : "Checking item",
      subtitle: `Quantity of ${product.quantity}`,
      imageUrl: getEntityImage(product.stacklineSku, "product"),
      visible: true,
      success: product.status === "pending" || isItemCheckoutSuccess(product),
      id: product.stacklineSku,
    };
  });
};

export const getOrderTotals = (
  order: UserRetailerCheckoutOrder | undefined
) => {
  if (!order) {
    return {
      subtotal: 0,
      shippingFees: 0,
      shippingDiscount: 0,
      shipping: 0,
      tax: 0,
      total: 0,
      giftCardAmount: 0,
    };
  }
  const subtotal = order?.orderSummary?.subtotal ?? 0;
  const shippingFees = order?.orderSummary?.shippingFees ?? 0;
  const shippingDiscount = Math.abs(order?.orderSummary?.shippingDiscount ?? 0);
  const shipping = shippingFees - shippingDiscount;
  const tax = order?.orderSummary?.tax ?? 0;
  const total = order?.orderSummary?.total ?? 0;
  const giftCardAmount = order?.orderSummary?.giftCard ?? 0;

  return {
    subtotal,
    shippingFees,
    shippingDiscount,
    shipping,
    tax,
    total,
    giftCardAmount,
  };
};

export const getSortedAddresses = (
  addresses: UserRetailerCheckoutOrder["availableAddresses"] = []
) => {
  const availableAddresses = addresses
    .slice()
    .sort((a, b) => {
      return a?.extendedAttributes?.isSelected
        ? -1
        : b?.extendedAttributes?.isSelected
        ? 1
        : 0;
    })
    .filter(
      (address) =>
        address &&
        address.firstName &&
        address.address1 &&
        address.city &&
        address.state &&
        address.zipCode
    );

  const filteredAddresses = availableAddresses.filter((address) => {
    const notAmazonFresh = !address?.firstName
      .toLocaleLowerCase()
      ?.includes("amazon fresh");
    const notWholeFoods = !address?.firstName
      .toLocaleLowerCase()
      ?.includes("whole foods");
    const notUPS = !address?.firstName.toLocaleLowerCase()?.includes("ups");
    return notAmazonFresh && notWholeFoods && notUPS;
  });

  const sortedShippingAddresses = filteredAddresses.sort((a, b) => {
    return a?.extendedAttributes?.isSelected
      ? -1
      : b?.extendedAttributes?.isSelected
      ? 1
      : 0;
  });

  return sortedShippingAddresses;
};

export const checkBagFull = (
  items: ShoppingCartItem[],
  allCarts: UserRetailerShoppingCart[]
) => {
  const itemCountByRetailerId = new Map<number, number>();
  const itemCountByProduct = new Map<string, number>();

  for (const item of items) {
    const retailerCount = itemCountByRetailerId.get(item.retailerId) ?? 0;
    itemCountByRetailerId.set(item.retailerId, retailerCount + item.quantity);

    const productCount = itemCountByProduct.get(item.stacklineSku) ?? 0;
    itemCountByProduct.set(item.stacklineSku, productCount + item.quantity);
  }

  for (const cart of allCarts) {
    for (const item of cart.items) {
      const productCount = itemCountByProduct.get(item.stacklineSku) ?? 0;
      const retailerCount = itemCountByRetailerId.get(cart.retailerId) ?? 0;

      itemCountByProduct.set(item.stacklineSku, productCount + item.quantity);
      itemCountByRetailerId.set(cart.retailerId, retailerCount + item.quantity);

      if (
        productCount + item.quantity >
        retailerLimits.maximumQuantityPerProduct
      ) {
        return true;
      }
    }
  }

  return false;
};

export const removeErrorProduct = async (
  stacklineSku: string,
  retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus
) => {
  const removeItem =
    retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.items?.find(
      (item) => item.stacklineSku === stacklineSku
    );
  if (!removeItem) {
    return;
  }

  const request = {
    retailerId:
      retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.retailerId,
    orderId: retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.orderId,
    brandId: retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.brandId,
    items: [
      {
        quantity: removeItem?.quantity,
        retailPrice: removeItem?.retailPrice,
        retailerSku: removeItem?.retailerSku,
        retailerId: removeItem?.retailerId,
      },
    ],
  };
  try {
    return await deleteOutOfStockItemsFromCheckoutOrder(request);
  } catch (e) {
    console.error("error", JSON.stringify(e));
  }
};

export const confirmPartiallyOutOfStock = async (
  stacklineSku: string,
  retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus,
  send: ActorRefFrom<ActorLogicFrom<typeof globalCheckoutMachine>>["send"]
) => {
  const removeItem =
    retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.items?.find(
      (item) => item.stacklineSku === stacklineSku
    );
  if (!removeItem) {
    return;
  }
  const request = {
    retailerId:
      retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.retailerId,
    orderId: retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.orderId,
    brandId: retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.brandId,
    items: [
      {
        quantity: removeItem?.quantity,
        retailPrice: removeItem?.retailPrice,
        retailerSku: removeItem?.retailerSku,
        retailerId: removeItem?.retailerId,
      },
    ],
  };

  try {
    const res = await updateUserConfirmationToCheckoutItems(request);
    send({
      type: "ON_CHECKOUT_STATUS_CHANGE",
      data: {
        retailerCheckoutOrderStatus: res,
      },
    });
  } catch (e) {
    console.error("error", JSON.stringify(e));
  }
};
