import {
  ChevronDownIcon,
  ChevronUpIcon,
  brandclub_colors,
} from "@brandclub/common-ui";
import { Collapse, styled } from "@mui/material";
import React from "react";

interface CollapsibleSectionProps {
  isOpen: boolean;
  onToggle: () => void;
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
  hideControl?: boolean;
  error?: boolean;
}

const StyledCollapsibleSection = styled("div")<{ error?: boolean }>(
  ({ theme, error }) => ({
    border: error
      ? `1px solid ${theme.palette.error.main}`
      : `1px solid ${brandclub_colors.blueWhiteBorder}`,
    borderRadius: 8,
    overflow: "hidden",
  })
);

const Header = styled("button")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "16px",
  border: "none",
  background: "transparent",
  cursor: "pointer",
  color: theme.palette.primary.main,
  fontSize: "16px",
  fontWeight: 500,
  textAlign: "left",
  "&:hover": {
    backgroundColor: brandclub_colors.blueWhite,
  },
}));

const Content = styled("div")(() => ({
  padding: "16px",
  borderTop: `1px solid ${brandclub_colors.blueWhiteBorder}`,
}));

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  isOpen,
  onToggle,
  title,
  children,
  disabled,
  hideControl = false,
  error,
}) => {
  return (
    <StyledCollapsibleSection error={error}>
      <Header disabled={disabled} onClick={onToggle}>
        {title}
        {!hideControl && (
          <>
            {isOpen ? (
              <ChevronUpIcon style={{ opacity: disabled ? 0.5 : 1 }} />
            ) : (
              <ChevronDownIcon style={{ opacity: disabled ? 0.5 : 1 }} />
            )}
          </>
        )}
      </Header>
      <Collapse in={isOpen}>
        <Content>{children}</Content>
      </Collapse>
    </StyledCollapsibleSection>
  );
};

export default CollapsibleSection;
