import { RetailerSyncSession, SyncSession, SyncStatus } from "@/types/misc";
import { GlobalSyncStatus, SyncStatusMessageType } from "./types";
import pluralize from "pluralize";

export const getSyncStatus = (
  syncStatusRes: RetailerSyncSession[],
  sessionType?: SyncSession["sessionType"]
): GlobalSyncStatus => {
  let res: GlobalSyncStatus = "normal";
  if (sessionType === "liveShopping") {
    return res;
  }
  syncStatusRes.forEach((s) => {
    if (isRetailerSyncing(s)) {
      res = "syncing";
    }
  });

  return res;
};

export const isRetailerSyncing = (retailerStatus: RetailerSyncSession) => {
  if (
    retailerStatus.syncStatus &&
    !["completed", "error", "timed_out", "cancelled"].includes(
      retailerStatus.syncStatus
    )
  ) {
    return true;
  }
  return false;
};

export const getSyncStatusMessage = (
  syncStatus: RetailerSyncSession,
  syncingRetailersCount = 1
): {
  title: string;
  message: string;
  status: SyncStatusMessageType;
} => {
  const connecting = {
    status: "connecting",
    title: `Connecting ${pluralize("account", syncingRetailersCount)}`,
    message: `We’re connecting your ${pluralize(
      "account",
      syncingRetailersCount
    )}. This may take a moment.`,
  } as const;
  const syncing = {
    status: "syncing",
    title: `Syncing ${pluralize("account", syncingRetailersCount)}`,
    message:
      "We’re checking purchases that qualify for rewards. This could take a minute.",
  } as const;
  const checkingRewards = {
    status: "checking_rewards",
    title: "Checking for rewards",
    message:
      "We’re calculating ways to maximize your rewards. We’re almost done.",
  } as const;
  const error = {
    status: "error",
    title: "Let’s try again",
    message: "We weren’t able to sync any of your accounts.",
  } as const;
  // user should be redirected wherever it's appropriate, there is no success messaging. We show the previous status message to avoid flickering
  const completed = {
    status: "completed",
    title: checkingRewards.title,
    message: checkingRewards.message,
  } as const;
  switch (syncStatus.syncStatus) {
    case "queued":
    case "pending":
    case "connecting":
    case "userintervention":
      return connecting;
    case "connected":
    case "scanning":
    case "scanning_completed":
      return syncing;
    case "processing_rewards":
      return checkingRewards;
    case "completed":
      return completed;
    case "timed_out":
    case "error":
    case "skipped":
      return error;
  }
};

export const sortBySyncStatusPriority = (
  sessions: RetailerSyncSession[]
): RetailerSyncSession[] => {
  return [...sessions].sort(
    (a, b) =>
      getSyncStatusPriority(a.syncStatus) - getSyncStatusPriority(b.syncStatus)
  );
};

export const getSyncStatusPriority = (syncStatus: SyncStatus): number => {
  switch (syncStatus) {
    case "queued":
    case "pending":
    case "connecting":
    case "userintervention":
      return 0; // prioritize connecting
    case "connected":
    case "scanning":
    case "scanning_completed":
      return 1; // next priority is syncing
    case "processing_rewards":
      return 2; // then checking rewards
    case "completed":
      return 3; // then completed
    case "timed_out":
    case "skipped":
    case "error":
      return 4; // next lowest is errors
    default:
      return 5; // lowest priority for anything else we haven't accounted for
  }
};
