import { ApolloConsumer, ApolloProvider } from "@apollo/client";
import { StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React from "react";
import { Provider as BusProvider } from "react-bus";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { getJwt } from "./Auth";
import "./index.css";
import getStore from "./redux/store";
import reportWebVitals from "./reportWebVitals";

import { SplitFactory } from "@splitsoftware/splitio-react";
import {
  brandclub_colors,
  DESKTOP_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "@brandclub/common-ui";
import {
  MOBILE_SCREEN_SIZE,
  SMALL_MOBILE_SCREEN_SIZE,
  XL_DESKTOP_SCREEN_SIZE,
} from "./components/AppNavigation/constants";
import reduxApolloClient from "./redux/reduxApolloClient";
import store from "./redux/store";

// Create the config for the SDK factory.
const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: "fm4ur66cflc3lt0jjrh6j3olsu0iebp4ji1j",
    key: "key",
  },
};

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: brandclub_colors.mainSeriesColor,
    },
    secondary: {
      main: brandclub_colors.lightGreen,
    },
  },
  breakpoints: {
    values: {
      xs: SMALL_MOBILE_SCREEN_SIZE,
      sm: MOBILE_SCREEN_SIZE,
      md: TABLET_SCREEN_SIZE,
      lg: DESKTOP_SCREEN_SIZE,
      xl: XL_DESKTOP_SCREEN_SIZE,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)"
          borderRadius: "6px",
          boxShadow: "0 1px 10px -4px #24252640",
        },
      },
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container as any);
root.render(
  <React.StrictMode>
    <ApolloProvider client={reduxApolloClient}>
      <ApolloConsumer>
        {(apolloClient) => (
          <Provider store={getStore(undefined, { apolloClient })}>
            <BusProvider>
              <SplitFactory config={sdkConfig}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <App />
                  </ThemeProvider>
                </StyledEngineProvider>
              </SplitFactory>
            </BusProvider>
          </Provider>
        )}
      </ApolloConsumer>
    </ApolloProvider>
  </React.StrictMode>
);

if (
  window.location.href.includes(import.meta.env.VITE_APP_DEV_UI_URL) ||
  window.location.href.includes(import.meta.env.VITE_APP_LOCALHOST_UI_URL)
) {
  //@ts-ignore
  window.getJwt = async () => {
    const jwt = await getJwt();
    console.log({ jwt });
  };
  //@ts-ignore
  window.store = store;
  //@ts-ignore
  window.getState = () => store().getState();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
