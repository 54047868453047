import { SolidButton } from "@/components/StoreComponents/StoreButton";
import { useAppSelector } from "@/redux/hooks";
import {
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeExpressCheckoutElementConfirmEvent } from "@stripe/stripe-js";
import { useState } from "react";

export const StripeCheckoutInner = ({
  clientSecret,
  send,
}: {
  clientSecret: string;
  send: (event: { type: string } & Record<string, any>) => void;
}) => {
  const elements = useElements();
  const stripe = useStripe();

  const user = useAppSelector((state) => state.userProfile);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePaymentSubmit = async () => {
    send({ type: "SUBMIT_PAYMENT_INTENT" });
    if (!stripe || !elements) {
      send({
        type: "PAYMENT_INTENT_ERROR",
        error: "An unexpected error occurred",
      });
      return;
    }

    setIsProcessing(true);
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/dashboard/debugging/stripe`,
        },
        redirect: "if_required",
      });

      if (error) {
        send({
          type: "PAYMENT_INTENT_ERROR",
          error: error.message ?? "An error occurred",
        });
      }
      if (paymentIntent) {
        send({ type: "PAYMENT_INTENT_SUCCESS", paymentIntent });
      }
    } catch (e) {
      send({
        type: "PAYMENT_INTENT_ERROR",
        error: "An unexpected error occurred",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
    send({ type: "SUBMIT_PAYMENT_INTENT" });
    if (!stripe || !elements) {
      send({
        type: "PAYMENT_INTENT_ERROR",
        error: "An unexpected error occurred",
      });
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      send({ type: "PAYMENT_INTENT_ERROR", error: submitError });
      setErrorMessage(submitError?.message ?? "");
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard/debugging/stripe`,
      },
      redirect: "if_required",
    });

    if (error) {
      if (error.message) {
        send({ type: "PAYMENT_INTENT_ERROR", error: error.message });
      }
    } else if (paymentIntent) {
      send({ type: "PAYMENT_INTENT_SUCCESS", paymentIntent });
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      {errorMessage && <div className="error_message">{errorMessage}</div>}

      <div style={{ marginBottom: "20px" }}>
        <h3>Express Checkout</h3>
        <ExpressCheckoutElement
          options={{
            emailRequired: true,
            phoneNumberRequired: true,
            shippingAddressRequired: true,
            billingAddressRequired: true,
            wallets: {
              applePay: "auto",
              googlePay: "auto",
            },
          }}
          onConfirm={onConfirm}
        />
      </div>

      <div>
        <h3>Card Payment</h3>
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                name: `${user.given_name} ${user.family_name}`,
                email: user.email,
                phone: user.phoneNumber,
              },
            },
          }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "22px",
          }}
        >
          <SolidButton onClick={handlePaymentSubmit} disabled={isProcessing}>
            {isProcessing ? "Processing..." : "Checkout"}
          </SolidButton>
        </div>
      </div>
    </>
  );
};
