import * as CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTOtp, syncConnections } from "../../../../../api";
import { useAppSelector } from "../../../../../redux/hooks";
import { Retailer } from "../../../../../redux/types";
import Loading from "../../../../Loading";
import StoreInput from "../../../../StoreComponents/StoreInput";
import { useSyncContext } from "../../../../SyncProvider";
import { AuthSignUpPageContainer } from "../../../Auth/AuthSignUpPageOutlet";
import AuthSolidButton from "../../../Auth/components/AuthSolidButton";
import { RetailerSyncFlowTypes } from "@brandclub/types";
import Box from "@mui/material/Box";
import { formatMoney } from "../../../../../utils/StringUtils";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material";
import { hexadecimal, MOBILE_SCREEN_SIZE } from "@brandclub/common-ui";

const ROW_SPACING = 25;

const RewardBoxContainer = styled("div")(() => ({
  padding: "26px 24.7px",

  maxWidth: "511px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  gap: "18px",
  borderRadius: "10px",
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: "10px",
    padding: "18px 16px",
  },
  ".reward_box_icon": {
    width: "70px",
    height: "70px",
    borderRadius: 999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: "50px",
      height: "50px",
      minWidth: "50px",
      minHeight: "50px",
    },
    ".reward_img": {
      width: "70px",
      height: "70px",
      minWidth: "70px",
      minHeight: "70px",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: "50px",
        height: "50px",
        minWidth: "50px",
        minHeight: "50px",
      },
    },
  },
  ".reward_box_body": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    ".reward_box_head": {
      fontSize: "20px",
      fontWeight: 700,
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: "16px",
      },
    },
    ".reward_box_text": {
      fontSize: "16px",
      fontWeight: 500,
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: "14px",
      },
    },
  },
}));

const RewardBox = ({ rewardAmount }: { rewardAmount?: number }) => {
  const theme = useTheme();

  const backgroundColor =
    theme?.customConnectReward?.backgroundColor ||
    hexadecimal(theme.palette.secondary.main)(20);

  const headerText =
    theme?.customConnectReward?.headerText || "EXCLUSIVE OFFER";
  const textColor =
    theme?.customConnectReward?.textColor || theme.palette.primary.main;
  const rewardText = rewardAmount
    ? `Earn ${formatMoney(rewardAmount)} when you complete this activity.`
    : "Access exclusive rewards when you connect.";

  return (
    <div
      style={{
        padding: "0 20px",
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
      }}
    >
      <RewardBoxContainer sx={{ backgroundColor: backgroundColor }}>
        <div className="reward_box_icon">
          <img
            src={"https://media.brandclub.com/brandclub/icons/rewardblock.webp"}
            alt="reward_box_icon"
            className="reward_img"
          />
        </div>
        <div className="reward_box_body">
          <div className="reward_box_head" style={{ color: textColor }}>
            {headerText}
          </div>
          <div className="reward_box_text" style={{ color: textColor }}>
            {rewardText}
          </div>
        </div>
      </RewardBoxContainer>
    </div>
  );
};

const ConnectRetailer = ({
  retailerId: retailerIdFromProps,
  onConnectCallback,
  connectOverwrite,
  redirectToSync = true,
  mode = RetailerSyncFlowTypes.CONNECT,
  errorMsg,
  className,
  additionalRewardAmount,
  disabled = false,
  buttonText = "Connect",
}: {
  retailerId?: number;
  onConnectCallback?: (retailerId: number) => Promise<void>;
  connectOverwrite?: (userName: string, password: string) => void;
  redirectToSync?: boolean;
  mode?: RetailerSyncFlowTypes.FIX | RetailerSyncFlowTypes.CONNECT;
  errorMsg?: string;
  className?: string;
  additionalRewardAmount?: number;
  disabled?: boolean;
  buttonText?: string;
}) => {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [retailer, setRetailer] = useState<Retailer>();
  const { retailerId: retailerIdFromParams } = useParams();
  const retailers = useAppSelector(({ retailers }) => retailers);
  const { connectRetailer } = useSyncContext();

  const [logoLoading, setLogoLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const theme = useTheme();

  const getUserNameFieldHeading = (retailer: Retailer) => {
    if (retailer.retailerLoginUsernameFieldTypes?.length) {
      const supportedFieldTypes = retailer.retailerLoginUsernameFieldTypes;
      if (
        supportedFieldTypes.includes("email") &&
        supportedFieldTypes.includes("phoneNumber")
      ) {
        return "Email or mobile phone";
      }
    }
    return "Email";
  };

  useEffect(() => {
    if (retailers && (retailerIdFromParams || retailerIdFromProps)) {
      const currentRetailer = retailers.find(
        (r) =>
          r.retailerId ===
          parseInt(`${retailerIdFromParams || retailerIdFromProps}`)
      );
      if (currentRetailer) {
        setRetailer(currentRetailer);
      }
    }
  }, [retailerIdFromParams, retailerIdFromProps, retailers]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (retailer) {
          const { token, currentStamp } = await getTOtp();
          const accountsDataEncrypted = await syncConnections({ currentStamp });
          const accounts = JSON.parse(
            CryptoJS.AES.decrypt(accountsDataEncrypted, token).toString(
              CryptoJS.enc.Utf8
            )
          );
          const account = accounts?.find(
            (x: any) => x.retailerId === retailer.parentRetailerId
          );
          if (account) {
            setUsername(account.credential.username);
            setPassword(account.credential.password);
          }
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [retailer]);

  const rewardAmount =
    (additionalRewardAmount || 0) +
    (retailer?.retailerConnectRewardAmount || 0);
  return (
    <AuthSignUpPageContainer className={className} sx={{}}>
      {!retailer || loading ? (
        <div className="page_title">
          <Loading dot />
        </div>
      ) : (
        <>
          <div
            className="auth_container"
            style={{
              minHeight: "auto",
            }}
          >
            <img
              style={{ contentVisibility: logoLoading ? "hidden" : "visible" }}
              onLoad={() => setLogoLoading(false)}
              onLoadStart={() => setLogoLoading(true)}
              className="retailer_logo"
              alt="r_logo"
              src={retailer?.logo}
            ></img>
            <Box className="page_title" marginTop={"30px"} textAlign="center">
              Login to continue
            </Box>
            <Box className="page_subtitle" textAlign="center">
              Use your {retailer.displayName} username and password.
            </Box>
            <StoreInput
              label={getUserNameFieldHeading(retailer)}
              inputStyle={{ width: "100%" }}
              style={{ marginTop: ROW_SPACING }}
              onChange={(e) => {
                setUsername(e.target.value as any);
              }}
              sx={{ maxWidth: "100%" }}
              disabled={submitting || disabled}
              value={username}
            />
            <StoreInput
              label="Password"
              type="password"
              inputStyle={{ width: "100%" }}
              style={{ marginTop: ROW_SPACING }}
              onChange={(e) => {
                setPassword(e.target.value as any);
              }}
              sx={{ maxWidth: "100%" }}
              disabled={submitting || disabled}
              value={password}
              errorMsg={errorMsg}
            />
            <AuthSolidButton
              style={{
                marginTop: ROW_SPACING + 5,
              }}
              disabled={
                submitting ||
                disabled ||
                !username ||
                !password ||
                username.length < 1 ||
                password.length < 1
              }
              onClick={async () => {
                setSubmitting(true);
                if (username && password && connectOverwrite) {
                  connectOverwrite(username, password);
                } else if (username && password) {
                  connectRetailer(
                    retailer.parentRetailerId || retailer.retailerId,
                    username,
                    password,
                    mode,
                    redirectToSync
                  );
                  if (onConnectCallback) {
                    await onConnectCallback(
                      retailer.parentRetailerId || retailer.retailerId
                    );
                  }
                }
                setSubmitting(false);
              }}
            >
              {buttonText}
            </AuthSolidButton>

            {theme.dtc && (
              <span className="terms" style={{ maxWidth: 282 }}>
                Rewards are subject to the Brandclub{" "}
                <a
                  href="https://brandclub.com/terms"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://brandclub.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Privacy Policy
                </a>
                .
              </span>
            )}
          </div>
          {theme.dtc && <RewardBox rewardAmount={rewardAmount} />}
        </>
      )}
    </AuthSignUpPageContainer>
  );
};

export default ConnectRetailer;
