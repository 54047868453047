import { StripeCheckoutInner } from "@/components/Stripe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useCheckoutUI } from "../../CheckoutUIProvider";

const stripePromise = loadStripe(
  "pk_test_51LnQyZJrInA7nkEZROUUcHyEsSs2CP3eWlIzOzIk6eMyGIKF8vsjZ4cGFjxrjZas6FlwTs2F9YvxrGsbdwojbxRO00IBijaZzD"
);

export const CheckoutEditPaymentForm = () => {
  const [state, send] = useCheckoutUI();
  const clientSecret = state.context.clientSecret;

  if (!clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <p>
        Note: this uses Stripe's sandbox/test mode and no payments will be
        charged (state.value: {state.value as string})
      </p>
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
        }}
      >
        <StripeCheckoutInner clientSecret={clientSecret} send={send} />
      </Elements>
    </>
  );
};
