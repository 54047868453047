import { useAppSelector } from "@/redux/hooks";
import { Retailer } from "@/redux/types";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { UserRetailerShoppingCart } from "../../types";

interface ICheckoutContextParams {
  setShipping: (shippingInfo: any) => void;
  shipping: any;
  amountToCharge: number | null;
  currentCart?: UserRetailerShoppingCart;
  currentRetailer?: Retailer;
  submitOrder: () => void;
}

// Create a context with a default value.
const CheckoutContext = createContext<ICheckoutContextParams>(
  {} as ICheckoutContextParams
);

// Create a Provider component.
export const FastCheckoutProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();

  // Selectors
  const allCarts = useAppSelector((state) => state.checkout.allCarts);
  const allRetailers = useAppSelector((state) => state.retailers);

  // State
  const [shipping, setShipping] = useState<any>();
  const currentRetailerId = allCarts[0]?.retailerId; // Placeholder

  const currentCart = allCarts.find(
    (cart) => cart.retailerId === currentRetailerId
  );
  const currentRetailer = allRetailers.find(
    (retailer) => retailer.retailerId === currentRetailerId
  );
  const amountToCharge = currentCart?.items.reduce(
    (acc, item) => acc + item.retailPrice * item.quantity,
    0
  );

  const afterSubmit = useCallback(() => {
    navigate("/checkoutSuccess");
  }, [navigate]);

  const submitOrder = useCallback(() => {
    afterSubmit();
  }, [afterSubmit]);

  const contextValue = useMemo(
    () => ({
      amountToCharge: amountToCharge ?? null,
      shipping,
      setShipping,
      currentCart,
      currentRetailer,
      submitOrder,
    }),
    [amountToCharge, shipping, currentCart, currentRetailer, submitOrder]
  );

  return (
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  );
};

// Custom hook to use the context
export const useCheckout = () => useContext(CheckoutContext);
