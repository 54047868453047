import { moveAllUserRetailerShoppingCarts } from "@/api/rest/checkoutApi";
import {
  AnalyticsService,
  AnalyticsTrackingEvent,
  tryGetVisitorId,
} from "@brandclub/common-ui";
import { useActor } from "@xstate/react";
import { Hub } from "aws-amplify";
import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useBus } from "react-bus";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ActorLogicFrom, ActorRefFrom, SnapshotFrom } from "xstate";
import { transferVisitorActions } from "../../../api/rest/authenticated/transferVisitorActions";
import {
  clearSharedAuthCookie,
  getUserSignedInState,
  getVisitorIdCookie,
  setOtpAuth,
} from "../../../Auth";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearCustomerSpendByBrand } from "../../../redux/reducers/customerSpendByBrand";
import { clearRewards } from "../../../redux/reducers/rewards";
import { clearUserProfile } from "../../../redux/reducers/userProfile";
import { CLOSE_LOGIN_DRAWER } from "../../../utils/busEvents";
import { useTrackActions } from "../../../utils/hooks/useTracking";
import Loading from "../../Loading";
import { userLoginMachine } from "./UserLoginMachines";
import { useSyncBrowserNavigationWithUserLoginState } from "./useSyncBrowserNavigationWithUserLoginState";
import { AuthHelper } from "./AuthHelper";
import { saveNewPhoneNumber, Status } from "./updatePhone/api";

export const UserLoginContext = createContext<UserLoginContextType>(
  null as never
);

const loadCartIntoUser = async () => {
  try {
    const uniqueVisitorId = await tryGetVisitorId();
    const uniqueVisitorIdFromCookie = getVisitorIdCookie();
    const uniqueVisitorIdToUse =
      uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
        ? uniqueVisitorIdFromCookie
        : uniqueVisitorId;
    if (uniqueVisitorIdToUse) {
      await moveAllUserRetailerShoppingCarts(uniqueVisitorIdToUse);
    }
  } catch (err) {
    console.error(err);
  }
};

const transferVisitorRewardsToUser = async () => {
  try {
    await transferVisitorActions();
  } catch (err) {
    console.error(err);
  }
};

const UserLoginProvider = ({ children }: { children: ReactElement }) => {
  const [trackAction] = useTrackActions();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const authConfig = useAppSelector(({ appConfig }) => appConfig?.authConfig);
  const domainConfig = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig
  );
  const [searchParams] = useSearchParams();
  // Capture the initial search param value on first render to avoid losing it during login redirects.
  const [ssoRedirectUrl] = useState(
    searchParams.get("ssoRedirectUrl") || searchParams.get("redirectUrl")
  );
  const [resendCooldown, setResendCooldown] = useState(0);

  const [submittingOtp, setSubmittingOtp] = useState(false);
  const [otpError, setOtpError] = useState<string | null>(null);

  const [submittingSignIn, setSubmittingSignIn] = useState(false);

  useEffect(() => {
    if (resendCooldown > 0) {
      setTimeout(() => setResendCooldown(resendCooldown - 1), 1000);
    } else {
      setResendCooldown(0);
    }
  }, [setResendCooldown, resendCooldown]);

  const verifyOtp = async (
    otp: string,
    otpSession?: any,
    successCallback?: () => void
  ) => {
    setSubmittingOtp(true);
    const verifyingUserSession = otpSession || snapshot.context.cognitoSession;
    await AuthHelper.answerCustomChallenge(verifyingUserSession, otp)
      .then(async (cognitoUser) => {
        await setOtpAuth(cognitoUser);

        successCallback?.();
      })
      .catch((err) => {
        setOtpError("Invalid code, please try again");
        console.error(err);
      })
      .finally(() => {
        setSubmittingOtp(false);
      });
  };

  const resendOtp = async (
    phoneNumber?: string,
    isNewUser?: boolean,
    successCallback?: (cognitoSession: any) => void
  ) => {
    setResendCooldown(30);
    try {
      setSubmittingOtp(true);
      const phoneNumberToUse = phoneNumber || snapshot.context.otpPhoneNumber;
      if (phoneNumberToUse === undefined) {
        throw new Error("Phone number is not defined");
      }
      const cognitoSession = await AuthHelper.sendAuthOtpWithSms(
        phoneNumberToUse,
        isNewUser || !!snapshot.context.isNewUser
      );
      successCallback?.(cognitoSession);
    } catch (e) {
      setOtpError(`An error occurred: Please try again after some time`);
      console.error(e);
    } finally {
      setSubmittingOtp(false);
    }
  };

  const bus = useBus();
  const navigate = useNavigate();

  const actor = useActor(userLoginMachine, {
    input: {
      ssoRedirectUrl,
      initialOpenPath: { pathname: location.pathname, search: location.search },
      navigate,
      reduxDispatch: dispatch,
      appConfig: {
        storeBrandingType: domainConfig?.storeBrandingType,
        brandId: domainConfig?.brandId,
        domainName: domainConfig?.domainName,
      },
    },
  });

  const [snapshot, send, actorRef] = actor;

  const [signInError, setSignInError] = useState<string | undefined>(
    snapshot.context.errorMessage
  );

  // used to keep login state in sync with browser navigation
  useSyncBrowserNavigationWithUserLoginState(send);

  const clearStateForSignOut = useCallback(async () => {
    clearSharedAuthCookie();
    // clear all auth snapshot.in redux store
    dispatch(clearUserProfile());
    dispatch(clearRewards());
    dispatch(clearCustomerSpendByBrand());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        const { signedIn } = await getUserSignedInState();
        if (signedIn) {
          AnalyticsService.track(AnalyticsTrackingEvent.AUTO_SIGN_IN, {});
        }
      } catch {}
      return {};
    })();
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signUp":
          trackAction(AnalyticsTrackingEvent.SIGN_UP, {});
          break;
        case "signIn":
          // complete the sign in process but we need to wait for the complete event to after doing token refresh
          trackAction(AnalyticsTrackingEvent.SIGN_IN, {});
          break;
        case "signIn_Complete":
          loadCartIntoUser();
          transferVisitorRewardsToUser();
          break;
        case "signOut":
          bus.emit(CLOSE_LOGIN_DRAWER);
          trackAction(AnalyticsTrackingEvent.SIGN_OUT, {});
          clearStateForSignOut();
          send({ type: "Hub.Auth.SignOut" });
          break;
        case "signIn_failure":
          trackAction(AnalyticsTrackingEvent.SIGN_IN_FAILURE, {});
          console.error("Auth Hub Event: signIn_failure", event, data);
          break;
        case "cognitoHostedUI_failure":
          console.error("Auth Hub Event: Sign in failure", data);
          break;
      }
    });

    return unsubscribe;
  }, [bus, clearStateForSignOut, send, trackAction]);

  // Authentication with phone number
  const signInWithPhone = async (
    phoneNumber: string,
    isNewUser: boolean,
    successCallback?: (isNewUser: boolean, session: any) => void
  ) => {
    try {
      const session = await AuthHelper.sendAuthOtpWithSms(
        phoneNumber,
        isNewUser
      );

      successCallback?.(isNewUser, session);
      return session;
    } catch (e: any) {
      if ((e.name || e.code) === "UserNotFoundException") {
        // Handle user not found
      }
      setSignInError(`An error occurred: Please try again after some time`);
    }
  };

  // Main sign-in function that can be reused across the app
  const signIn = async (
    phoneNumber: string,
    isUpdatePhoneFlow = false,
    successCallback?: (isNewUser: boolean, session: any) => void
  ) => {
    setSubmittingSignIn(true);

    try {
      if (isUpdatePhoneFlow) {
        const session = snapshot.context.session;
        const sessionId = snapshot.context.sessionId;

        if (!session || !sessionId) {
          throw new Error("Session not found");
        }

        try {
          const { status, errorMessage } = await saveNewPhoneNumber({
            session,
            sessionId,
            phoneNumber,
          });

          if (status === Status.success) {
            await signInWithPhone(phoneNumber, false, successCallback);
          } else {
            setSignInError(
              errorMessage ||
                `An error occurred: Please try again after some time`
            );
          }
        } catch (e: any) {
          setSignInError(`An error occurred: Please try again after some time`);
          console.error(e);
        }
      } else {
        try {
          await AuthHelper.signUp(phoneNumber);
          return await signInWithPhone(phoneNumber, true, successCallback);
        } catch (e: any) {
          if (e.code === "UsernameExistsException") {
            return await signInWithPhone(phoneNumber, false, successCallback);
          } else {
            throw e;
          }
        }
      }
    } finally {
      setSubmittingSignIn(false);
    }
  };

  if (!authConfig) {
    return <Loading fullscreen star />;
  }

  return (
    <UserLoginContext.Provider
      value={{
        actorRef: actorRef,
        snapshot: snapshot,
        send: send,
        signIn: signIn,
        submittingSignIn: submittingSignIn,
        signInError: signInError,
        setSignInError: setSignInError,
        resendCooldown: resendCooldown,
        submittingOtp: submittingOtp,
        otpError: otpError,
        setOtpError: setOtpError,
        verifyOtp: verifyOtp,
        resendOtp: resendOtp,
      }}
    >
      {children}
    </UserLoginContext.Provider>
  );
};

export type UserLoginContextType = {
  actorRef: ActorRefFrom<ActorLogicFrom<typeof userLoginMachine>>;
  snapshot: SnapshotFrom<typeof userLoginMachine>;
  send: ActorRefFrom<ActorLogicFrom<typeof userLoginMachine>>["send"];
  signIn: (
    phoneNumber: string,
    isUpdatePhoneFlow: boolean,
    successCallback?: (isNewUser: boolean, session: any) => void
  ) => Promise<void>;
  submittingSignIn: boolean;
  signInError: string | undefined;
  setSignInError: (errorMsg: string) => void;
  resendCooldown: number;
  submittingOtp: boolean;
  otpError: string | null;
  setOtpError: (errorMsg: string) => void;
  verifyOtp: (
    otp: string,
    otpSession?: any,
    successCallback?: () => void
  ) => Promise<void>;
  resendOtp: (
    phoneNumber?: string,
    isNewUser?: boolean,
    successCallback?: (cognitoSession: any) => void
  ) => Promise<void>;
};

export default UserLoginProvider;
