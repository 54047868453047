import { getAuthorizationHeader } from "@/Auth";
import axios from "@/utils/axios";
import Stripe from "stripe";

export const createPaymentIntent = async (amountAsCents: number) => {
  try {
    const { data } = await axios.post(
      "/stripe/create-payment-intent",
      {
        amount: Math.round(amountAsCents * 100),
      },
      {
        headers: {
          ...(await getAuthorizationHeader()),
        },
      }
    );

    return data as Stripe.PaymentIntent;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    throw error;
  }
};
